/* eslint-disable vue/html-self-closing */
<template>

	<div>
		<!-- Filters -->
		<top-deposits-list-filters
			:username-filter.sync="usernameFilter"
			:user-id-filter.sync="userIdFilter"
			:amount-filter.sync="amountFilter"
			:count-user-filter.sync="countUserFilter"
			:fromdate-filter.sync="fromdateFilter"
			:todate-filter.sync="todateFilter"
		/>

		<b-card
			no-body
			class="mb-0"
		>
			<b-table
				ref="refTopDepositListTable"
				class="position-relative table-white-space"
				:items="fetchTopDeposits"
				responsive
				:fields="tableColumns"
				primary-key="id"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No matching records found"
				:sort-desc.sync="isSortDirDesc"
			>
				<template #cell(index)="data">
					{{ data.index + 1 }}
				</template>

				<!-- Column: Name -->
				<template #cell(username)="data">
					<b-media vertical-align="center">
						<b-link
							:to="{ name: 'apps-users-view', params: { id: data.item.user_id } }"
							class="font-weight-bold d-block text-nowrap"
						>
							{{ data.item.user ? data.item.user.username :""}}
						</b-link>
					</b-media>
				</template>

				<template #cell(amount)="data">
					{{ numberFormat(data.item.amount) }}
				</template>

				<template #cell(transaction)="data">
					{{ numberFormat(data.item.quantityTransaction) }}
				</template>

			</b-table>

			<div class="mx-2 mb-2">
				<b-row>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
					</b-col>
					<!-- Pagination -->
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>

						<b-pagination
							v-model="currentPage"
							:total-rows="totalTopDeposits"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>

					</b-col>

				</b-row>
			</div>
		</b-card>
	</div>

</template>

<script>
import {
	BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
	BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, numberFormat, formatDateTime } from '@core/utils/filter'
import TopDepositsListFilters from './TopDepositsListFilters.vue'
import useTopDepositsList from './useTopDepositsList'
import topDepositStoreModule from './topDepositStoreModule'

export default {
	components: {
		TopDepositsListFilters,
		BCard,
		BRow,
		BCol,
		BFormInput,
		BButton,
		BTable,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		vSelect,
	},
	setup() {
		const CAMPAIGN_RISK_MANAGEMENT_TOP_DEPOSIT_MODULE_NAME = 'campaign-risk-management-top-deposits'

		// Register module
		if (!store.hasModule(CAMPAIGN_RISK_MANAGEMENT_TOP_DEPOSIT_MODULE_NAME)) store.registerModule(CAMPAIGN_RISK_MANAGEMENT_TOP_DEPOSIT_MODULE_NAME, topDepositStoreModule)

		// UnRegister on leave
		onUnmounted(() => {
			if (store.hasModule(CAMPAIGN_RISK_MANAGEMENT_TOP_DEPOSIT_MODULE_NAME)) store.unregisterModule(CAMPAIGN_RISK_MANAGEMENT_TOP_DEPOSIT_MODULE_NAME)
		})

		const {
			fetchTopDeposits,
			tableColumns,
			perPage,
			currentPage,
			totalTopDeposits,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refTopDepositListTable,
			refetchData,

			// UI
			// Extra Filters
			usernameFilter,
			userIdFilter,
			amountFilter,
			countUserFilter,
			fromdateFilter,
			todateFilter,
		} = useTopDepositsList()

		return {

			fetchTopDeposits,
			tableColumns,
			perPage,
			currentPage,
			totalTopDeposits,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refTopDepositListTable,
			refetchData,

			// Filter
			avatarText,
			numberFormat,
			formatDateTime,

			// Extra Filters
			usernameFilter,
			userIdFilter,
			amountFilter,
			countUserFilter,
			fromdateFilter,
			todateFilter,
		}
	},
}
</script>
import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title, paginateArray, sortCompare } from '@core/utils/filter'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useTopDepositsList() {
	// Use toast
	const toast = useToast()

	const refTopDepositListTable = ref(null)

	// Table Handlers
	const tableColumns = [
		'index',
		{ key: 'username' },
		{ key: 'amount' },
		{ key: 'transaction', label: 'quantity transactions' }
	]
	const perPage = ref(20)
	const totalTopDeposits = ref(0)
	const currentPage = ref(1)
	const perPageOptions = [10, 25, 50, 100]
	const searchQuery = ref('')
	const sortBy = ref('id')
	const isSortDirDesc = ref(true)
	const usernameFilter = ref(null)
	const userIdFilter = ref(null)
	const amountFilter = ref(null)
	const countUserFilter = ref(null)
	const fromdateFilter = ref("")
	const todateFilter = ref("")

	const refetchData = () => {
		refTopDepositListTable.value.refresh()
	}

	watch([currentPage, usernameFilter, userIdFilter, amountFilter, countUserFilter, fromdateFilter, todateFilter], () => {
		refetchData()
	})

	const fetchTopDeposits = (ctx, callback) => {
		store
			.dispatch('campaign-risk-management-top-deposits/fetchTopDeposits', {
				page: currentPage.value,
				username: usernameFilter.value,
				amount: amountFilter.value,
				countUser: countUserFilter.value,
				userId: userIdFilter.value,
				fromdate: fromdateFilter.value === "" ? null : fromdateFilter.value,
				todate: todateFilter.value === "" ? null : todateFilter.value,
			})
			.then(response => {
				if (response.data.code == '00') {
					callback(Object.values(removeObjectItem(response.data.data)))
					totalTopDeposits.value = response.data.count
					perPage.value = 25
				}
			})
	}

	const dataMeta = computed(() => {
		const localItemsCount = refTopDepositListTable.value ? refTopDepositListTable.value.localItems.length : 0
		return {
			from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
			to: perPage.value * (currentPage.value - 1) + localItemsCount,
			of: totalTopDeposits.value,
		}
	})

	const removeObjectItem = (object) => {
		delete object.totalAmount
		delete object.totalQuantityTransaction
		delete object.totalSuccess
		delete object.totalTransactionSuccess
		delete object.totalPending
		delete object.totalTransactionPending
		delete object.totalFail
		delete object.totalTransactionFail
		return object
	}

	return {
		fetchTopDeposits,
		tableColumns,
		perPage,
		currentPage,
		totalTopDeposits,
		dataMeta,
		perPageOptions,
		searchQuery,
		sortBy,
		isSortDirDesc,
		refTopDepositListTable,

		refetchData,

		// Extra Filters
		usernameFilter,
		userIdFilter,
		amountFilter,
		countUserFilter,
		fromdateFilter,
		todateFilter,
	}
}

<template>
  <b-card no-body>
    <b-card-body>
      <b-row class="filter">
        <!-- Name -->

        <b-col cols="12" md="4" class="mb-2">
          <label>{{ $t("User Name") }}</label>
          <b-form-input
            v-model="filter.username"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
          />
        </b-col>

        <b-col cols="12" md="4" class="mb-2">
          <label>{{ $t("user_id") }}</label>
          <b-form-input
            v-model="filter.user_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
          />
        </b-col>

        <b-col cols="12" md="4" class="mb-2">
          <label>{{ $t("Amount") }}</label>
          <b-form-input
            v-model="filter.amount"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
          />
        </b-col>

        <b-col cols="12" md="4" class="mb-0">
          <label>{{ $t("Fromdate") }}</label>
          <flat-pickr
            id="fromDate"
            v-model="filter.fromdate"
            class="form-control"
            placeholder="YYYY-MM-DD"
  
          />
        </b-col>

        <b-col cols="12" md="4" class="mb-0">
          <label>{{ $t("Todate") }}</label>
          <flat-pickr
            id="todate"
            v-model="filter.todate"
            class="form-control"
            placeholder="YYYY-MM-DD"

          />
        </b-col>
        <!-- action -->
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button variant="outline-secondary" @click="resetFilter()">
            {{ $t("Reset") }}
          </b-button>
          <b-button variant="primary" class="ml-2" @click="searchFilter()">
            {{ $t("Search") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BFormInput,
  BCard,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BFormGroup,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import store from "@/store";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardBody,
    vSelect,
    flatPickr,
    BFormGroup,
  },
  props: {
    usernameFilter: {
      type: [String, null],
      default: null,
    },
    amountFilter: {
      type: [String, null],
      default: null,
    },

    userIdFilter: {
      type: [String, null],
      default: null,
    },
    fromdateFilter: {
      type: [String, null],
      default: null,
    },
    todateFilter: {
      type: [String, null],
      default: null,
    },
  },
  methods: {},
  setup(props, { emit }) {
    const productIdOptions = ref();
    const filter = ref({
      username: props.usernameFilter,
      user_id: props.userIdFilter,
      amount: props.amountFilter,
      fromdate: props.fromdateFilter,
      todate: props.todateFilter,
    });
    const resetFilter = () => {
      emit("update:usernameFilter", null);
      emit("update:amountFilter", null);
      emit("update:user_idFilter", null);
      emit("update:fromdateFilter", null);
      emit("update:todateFilter", null);
      filter.value = {
        username: null,
        amount: null,
        user_id: null,
        fromdate: null,
        todate: null,
      };
    };

    const searchFilter = () => {
      emit("update:usernameFilter", filter.value.username);
      emit("update:amountFilter", filter.value.amount);
      emit("update:userIdFilter", filter.value.user_id);
      // emit("update:ipFilter", filter.value.ip);
      emit("update:fromdateFilter", filter.value.fromdate);
      emit("update:todateFilter", filter.value.todate);
      // emit("update:phoneFilter", filter.value.phone);
    };
    return {
      resetFilter,
      searchFilter,
      filter,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

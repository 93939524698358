/* eslint-disable indent */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */
// import axios from 'axios'
import { paginateArray, sortCompare } from "@core/utils/filter";
import instance from "@/libs/axios.instance";
import authHeader from "@/libs/auth.header";

class TopDepositService {
  getTopDepositLists(queryParams) {
    const pars = {
      username: queryParams.username,
      userId: queryParams.userId,
      amount: queryParams.amount,
      countUser: queryParams.countUser,
      fromDate: queryParams.fromdate,
      toDate: queryParams.todate,
      pageNumber: queryParams.page,
    };
    return instance.get("/api/top-deposit", {
      headers: authHeader(),
      params: pars,
    });
    /* .then(
					response => {
						const data = response.data.data
						const datas = {
							totalAmount: data.totalAmount,
							totalQuantityTransaction: data.totalQuantityTransaction,
							topDeposits: Object.values(this.removeObjectItem(data)),
							total: response.data.count,
							pageSize: 25//queryParams.page == 1 ? response.data.data.length : 25
						}
						return datas
					}
				) */
  }

  getDepositsAnalyticToday() {
    return instance.get("/api/Deposit/analytic/today", {
      headers: authHeader(),
    });
  }
  getWithdrawalsAnalyticToday() {
    return instance.get("/api/Withdrawal/analytic/today", {
      headers: authHeader(),
    });
  }
  removeObjectItem(object) {
    delete object.totalAmount;
    delete object.totalQuantityTransaction;
    return object;
  }
}

export default new TopDepositService();
